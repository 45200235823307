import { useState } from 'react'
import { useLazyGetReportTotalsQuery } from './reportsApiSlice'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ReportsList from './ReportsList'
import BeatLoader from 'react-spinners/BeatLoader'
import clsx from 'clsx'
import { useTheme } from '@mui/material'

const ReportTotalsExpanded = () => {
  const theme = useTheme()
  const [year, setYear] = useState('')
  const reportType = 'Totals Expanded'
  const [showTable, setShowTable] = useState(false)
  const [getReportTotals, { data, isLoading, isFetching, isError, error }] = useLazyGetReportTotalsQuery({ year })

  const handleFilterSubmit = async (e) => {
    e.preventDefault()
    await getReportTotals({ year })
    setShowTable(true)
  }

  const columns = [
    {
      field: 'monthYear',
      headerName: 'Month-Year',
      maxWidth: 225,
      minWidth: 100,
      width: 100,
      resizable: false,
      hideable: false,
      sortable: false,
      type: 'string'
    },
    {
      field: 'allUnitsShipped',
      headerName: 'Units Shipped',
      maxWidth: 225,
      minWidth: 100,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      hideable: false,
      type: 'number',
      headerClassName: 'bg-slateDark'
    },
    {
      field: 'allUnitsRegistered',
      headerName: 'Units Registered',
      maxWidth: 225,
      minWidth: 100,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-slateDark'
    },
    {
      field: 'replacedCompetitorOwsCondition1',
      headerName: '350-1000',
      maxWidth: 100,
      minWidth: 100,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      hideable: false,
      type: 'number',
      headerClassName: 'bg-maroonDark'
    },
    {
      field: 'replacedCompetitorOwsCondition2',
      headerName: '1500 & 2000',
      maxWidth: 105,
      minWidth: 105,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-maroonDark'
    },
    {
      field: 'replacedCompetitorOwsCondition3',
      headerName: 'Super',
      maxWidth: 85,
      minWidth: 75,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-maroonDark'
    },
    {
      field: 'replacedCompetitorOwsOem',
      headerName: 'OEM',
      maxWidth: 85,
      minWidth: 75,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-maroonDark'
    },
    {
      field: 'replacedCompetitorOwsTotal',
      headerName: 'Total',
      maxWidth: 85,
      minWidth: 75,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-maroonDark',
      cellClassName: (params) => (params.value == null ? '' : clsx('totals', params.row._id === 13 ? 'no-style' : ''))
    },
    {
      field: 'crpReplacementCondition1',
      headerName: '350-1000',
      maxWidth: 100,
      minWidth: 100,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-eggplantDark'
    },
    {
      field: 'crpReplacementCondition2',
      headerName: '1500 & 2000',
      maxWidth: 105,
      minWidth: 105,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-eggplantDark'
    },
    {
      field: 'crpReplacementCondition3',
      headerName: 'Super',
      maxWidth: 85,
      minWidth: 75,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-eggplantDark'
    },
    {
      field: 'crpReplacementOem',
      headerName: 'OEM',
      maxWidth: 85,
      minWidth: 75,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-eggplantDark'
    },
    {
      field: 'crpReplacementTotal',
      headerName: 'Total',
      maxWidth: 85,
      minWidth: 75,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-eggplantDark',
      cellClassName: (params) => (params.value == null ? '' : clsx('totals', params.row._id === 13 ? 'no-style' : ''))
    },
    {
      field: 'firstTimeOwsCustomerCondition1',
      headerName: '350-1000',
      maxWidth: 100,
      minWidth: 100,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-seafoamDark'
    },
    {
      field: 'firstTimeOwsCustomerCondition2',
      headerName: '1500 & 2000',
      maxWidth: 105,
      minWidth: 105,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-seafoamDark'
    },
    {
      field: 'firstTimeOwsCustomerCondition3',
      headerName: 'Super',
      maxWidth: 85,
      minWidth: 75,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-seafoamDark'
    },
    {
      field: 'firstTimeOwsCustomerOem',
      headerName: 'OEM',
      maxWidth: 85,
      minWidth: 75,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-seafoamDark'
    },
    {
      field: 'firstTimeOwsCustomerTotal',
      headerName: 'Total',
      maxWidth: 85,
      minWidth: 75,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-seafoamDark',
      cellClassName: (params) => (params.value == null ? '' : clsx('totals', params.row._id === 13 ? 'no-style' : ''))
    },
    {
      field: 'dontKnow',
      headerName: "Don't Know",
      maxWidth: 100,
      minWidth: 100,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-slateDark'
    },
    {
      field: 'everythingElse',
      headerName: 'Everything Else',
      maxWidth: 200,
      minWidth: 100,
      flex: 1,
      height: 45,
      sortable: false,
      resizable: false,
      type: 'number',
      headerClassName: 'bg-slateDark'
    }
  ]

  const columnGroupingModel = [
    {
      groupId: 'Replace Competitor OWS',
      children: [{ field: 'replacedCompetitorOwsCondition1' }, { field: 'replacedCompetitorOwsCondition2' }, { field: 'replacedCompetitorOwsCondition3' }, { field: 'replacedCompetitorOwsOem' }, { field: 'replacedCompetitorOwsTotal' }],
      headerClassName: 'bg-maroonDark',
      headerAlign: 'center'
    },
    {
      groupId: 'CRP Replacement',
      children: [{ field: 'crpReplacementCondition1' }, { field: 'crpReplacementCondition2' }, { field: 'crpReplacementCondition3' }, { field: 'crpReplacementOem' }, { field: 'crpReplacementTotal' }],
      headerClassName: 'bg-eggplantDark',
      headerAlign: 'center'
    },
    {
      groupId: 'First-Time OWS Customer',
      children: [{ field: 'firstTimeOwsCustomerCondition1' }, { field: 'firstTimeOwsCustomerCondition2' }, { field: 'firstTimeOwsCustomerCondition3' }, { field: 'firstTimeOwsCustomerOem' }, { field: 'firstTimeOwsCustomerTotal' }],
      headerClassName: 'bg-seafoamDark',
      headerAlign: 'center'
    }
  ]

  // creates an array of years from 2016 to the current year.
  // this is used for the select dropdown
  const years = Array.from(Array(new Date().getFullYear() - 2015), (_, i) => (i + 2016).toString())

  return (
    <>
      <Grid
        container
        spacing={2}
        marginTop={1}
        marginBottom={1}
        alignItems="flex-end">
        <Grid xs="auto">
          <Box>
            <FormControl sx={{ width: 400, mb: 0 }}>
              <InputLabel
                id="report-type"
                sx={{ backgroundColor: '#FFF', [theme.getColorSchemeSelector('dark')]: { backgroundColor: '#121212' }, px: 1 }}>
                Choose a report year
              </InputLabel>
              <Select
                labelId="report-type"
                variant="outlined"
                value={year}
                onChange={(e) => setYear(e.target.value)}>
                {years.map((year) => (
                  <MenuItem
                    key={year}
                    value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid xs="auto">
          <Button
            variant="contained"
            sx={{ mr: 1 }}
            onClick={handleFilterSubmit}
            disabled={year ? false : true}>
            Submit
          </Button>
        </Grid>
      </Grid>

      {isLoading || isFetching ? (
        <BeatLoader
          color={'#333'}
          size={20}
          style={{ margin: '8px' }}
        />
      ) : isError ? (
        <Typography>{error?.message || 'An error occurred.'}</Typography>
      ) : (
        <>
          {showTable && (
            <ReportsList
              equipments={data}
              columns={columns}
              columnGroupingModel={columnGroupingModel}
              reportType={reportType}
            />
          )}
        </>
      )}
    </>
  )
}

export default ReportTotalsExpanded
